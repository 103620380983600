import { useState, useEffect, useRef, forwardRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { OptionalSiteLinkWrapper, Image as SiteImage, VideoPlayer } from 'components/shared';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import renderLines from 'components/shared/renderLines';
import { useInView, useUI } from 'hooks';
import springConfig from 'lib/utils/springConfigs';
import validateRequired from './validate-required';
import withPropsValidation from './with-props-validation.tsx';

const BannerContainer = forwardRef(
  ({ children, triggerAnimation, onMouseOver, onMouseLeave, shouldAnimate, order }, ref) => {
    const containerStyle = useSpring({
      transform: triggerAnimation || !shouldAnimate ? 'scale(1)' : 'scale(0.97)',
      config: {
        tension: 388,
        friction: 86,
        velocity: 0.019,
      },
      delay: 200,
    });

    return (
      <animated.div
        style={containerStyle}
        className={`
          relative ${order === 0 ? 'flex-[1_0_100%]' : 'flex-[1_0_45%]'} aspect-[0.8]
          overflow-hidden
           md:h-max
           md:flex-1
          `}
        ref={ref}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </animated.div>
    );
  }
);

const BannerContent = ({
  isDesktop,
  image,
  mobileImage,
  hoverImage,
  mobileHoverImage,
  title,
  link,
  vimeoUrl,
  mobileVimeoUrl,
  triggerAnimation,
  hoverAnimation,
  shouldAnimate,
  order,
}) => {
  const titleStyle = useSpring({
    transform: triggerAnimation || !shouldAnimate ? 'translateY(0%)' : 'translateY(100%)',
    config: springConfig.easeInButBetter,
    delay: 500,
  });

  const imageStyle = useSpring({
    transform: hoverAnimation ? 'scale(1)' : 'scale(1.03)',
    opacity: hoverAnimation && shouldAnimate ? 1 : 0,
    config: springConfig.easeInButStickier,
  });

  const imageSizes = '(max-width: 767px) 85vw, (min-width: 768px) 45vw';

  const imageValue =
    !vimeoUrl && !mobileVimeoUrl && isDesktop ? image || mobileImage : mobileImage || image;
  const hoverImageValue =
    !vimeoUrl && !mobileVimeoUrl && isDesktop
      ? hoverImage || mobileHoverImage
      : mobileHoverImage || hoverImage;
  const vimeoUrlValue = isDesktop ? vimeoUrl : mobileVimeoUrl;

  return (
    <>
      {imageValue && (
        <SiteImage
          sizes={imageSizes}
          src={imageValue}
          className="absolute h-full w-full md:aspect-[0.8]"
        />
      )}
      {hoverImageValue && (
        <animated.div style={imageStyle} className="absolute h-full w-full">
          <SiteImage sizes={imageSizes} src={hoverImageValue} className="absolute h-full w-full" />
        </animated.div>
      )}
      {vimeoUrlValue && (
        <VideoPlayer videoURL={vimeoUrlValue} className="h-full w-full" videoClassName="absolute" />
      )}
      <OptionalSiteLinkWrapper
        link={link}
        onClick={() => {
          trackInWindow({
            event: trackEvents.componentBannerClick,
            page_path: window.location.pathname,
            component_name: title,
          });
        }}
      >
        <div className="absolute inset-0 flex h-full w-full flex-col items-center justify-center p-[10px] text-center text-white md:p-[20px]">
          {title && (
            <h1
              className={`my-[12px] overflow-y-hidden font-nimbus-sans-extd-d ${
                order === 0
                  ? 'text-[1.25rem] tracking-tight'
                  : 'text-[0.875rem] tracking-[-0.0175rem]'
              }  font-bold  uppercase leading-[90%] md:text-[1.5rem]`}
            >
              <animated.div style={titleStyle}>{renderLines(title)}</animated.div>
            </h1>
          )}
        </div>
      </OptionalSiteLinkWrapper>
    </>
  );
};

const renderBanner =
  isDesktop =>
  ({
    sys,
    image,
    mobileImage,
    hoverImage,
    mobileHoverImage,
    subtitle,
    title,
    link,
    fullHeight,
    vimeoUrl,
    mobileVimeoUrl,
    order,
  }) => {
    const bannerRef = useRef();
    const onScreen = useInView(bannerRef);
    const [triggerAnimation, setTriggerAnimation] = useState(false);
    const [bannerHovered, setBannerHovered] = useState(false);

    useEffect(() => {
      if (onScreen) {
        setTriggerAnimation(true);
      } else setTriggerAnimation(false);
    }, [onScreen]);

    return (
      <BannerContainer
        key={sys?.id}
        fullHeight={fullHeight}
        triggerAnimation={triggerAnimation}
        onMouseOver={() => setBannerHovered(true)}
        onMouseLeave={() => setBannerHovered(false)}
        ref={bannerRef}
        isDesktop={isDesktop}
        order={order}
      >
        <BannerContent
          isDesktop={isDesktop}
          image={image}
          mobileImage={mobileImage}
          hoverImage={hoverImage}
          mobileHoverImage={mobileHoverImage}
          vimeoUrl={vimeoUrl}
          mobileVimeoUrl={mobileVimeoUrl}
          subtitle={subtitle}
          title={title}
          link={link}
          triggerAnimation={triggerAnimation}
          hoverAnimation={bannerHovered}
          order={order}
        />
      </BannerContainer>
    );
  };

const Section = ({ data: { bannersCollection, withGap } }) => {
  const banners = bannersCollection.items.filter(Boolean);
  const { isDesktop } = useUI();
  const orderedBanners = banners.map((item, idx) => ({ ...item, order: idx }));

  return (
    <div
      className={`${
        withGap ? ' gap-[0.94rem] p-[0.94rem] !pt-0 ' : ''
      } relative flex w-full flex-row flex-wrap antialiased`}
    >
      {orderedBanners.map(renderBanner(isDesktop))}
    </div>
  );
};

export default withPropsValidation(Section, ({ data: { name, bannersCollection } }) =>
  validateRequired({ Name: name, Banners: bannersCollection.items })
);
